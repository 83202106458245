import React, { useState } from "react";
import styles from "./../style/agecal.module.css";
import AgeComponent from "./AgeComponent";
import { Helmet } from "react-helmet";

function AgeCalc() {
  const [birthDate, setBirthDate] = useState("");
  const [ageDetails, setAgeDetails] = useState(null);

  const calculateAge = () => {
    if (birthDate) {
      const today = new Date();
      const birthDateObj = new Date(birthDate);

      // Calculate total years
      let years = today.getFullYear() - birthDateObj.getFullYear();

      // Calculate month difference
      let months = today.getMonth() - birthDateObj.getMonth();
      if (months < 0) {
        years--; // Adjust years if the birthday hasn't occurred yet in this year
        months += 12; // Add 12 to make it a positive month difference
      }

      // Calculate day difference
      let days = today.getDate() - birthDateObj.getDate();
      if (days < 0) {
        months--; // Adjust month if the day hasn't passed in this month
        const lastMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        ).getDate(); // Days in the previous month
        days += lastMonth; // Add days from the last month
      }

      // Calculate hour difference
      const nowHours = today.getHours();
      const birthHours = birthDateObj.getHours();
      let hours = nowHours - birthHours;
      if (hours < 0) {
        days--; // Adjust days if the hours haven't passed today
        hours += 24; // Add 24 to make it a positive hour difference
      }

      setAgeDetails({ years, months, days, hours });
    } else {
      setAgeDetails(null);
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Age calculate</title>
        <meta name="description" content="Age calculator" />
        <meta name="keywords" content="Age calculator" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h1>Age Calculator</h1>
      <div className={styles.bheight}>
        <div>
          <label htmlFor="birthDate">Enter your birth date: </label>
          <input
            type="date"
            id="birthDate"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
          <button className="btn btn-success" onClick={calculateAge}>
            Calculate Age
          </button>
        </div>
        {ageDetails && (
          <div>
            <h2>Your age is:</h2>
            <p>
              {ageDetails.years} Years, {ageDetails.months} Months,{" "}
              {ageDetails.days} Days, and {ageDetails.hours} Hours
            </p>
          </div>
        )}
      </div>
      <AgeComponent />
    </div>
  );
}

export default AgeCalc;
