import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./../style/quotes.module.css";
import Quotescomponent from "./Quotescomponent";
import { Helmet } from "react-helmet";

const Quote = () => {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");

  const fetchQuote = async () => {
    try {
      const response = await axios.get("https://api.quotable.io/random");
      setQuote(response.data.content);
      setAuthor(response.data.author);
    } catch (error) {
      console.error("Error fetching the quote", error);
    }
  };

  useEffect(() => {
    fetchQuote(); // Fetch a quote when the component mounts
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>Random Quote Generator | Hoe tool</title>
        <meta
          name="description"
          content="To get random quotes in single button click, Best quotes generator tool"
        />
        <meta
          name="keywords"
          content="quotes generator, Hoe tool, quotes software, quotes generator tool, small tool"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.App}>
        <h2>Random Quote Generator</h2>
        <p style={{ fontStyle: "italic", fontSize: "24px" }}>"{quote}"</p>
        <p style={{ fontWeight: "bold" }}>- {author}</p>
        <button onClick={fetchQuote} className={styles.button}>
          Get New Quote
        </button>
      </div>
      <Quotescomponent />
    </div>
  );
};

export default Quote;
