import React, { useState } from "react";
import styles from "./../style/ascii.module.css";
import Asciicomponent from "./asciicomponent";
import { Helmet } from "react-helmet";
function App() {
  const [asciiText, setAsciiText] = useState("");
  const [binaryResult, setBinaryResult] = useState("");

  // Function to convert ASCII to binary
  const convertToBinary = (text) => {
    return text
      .split("") // Split text into characters
      .map((char) => char.charCodeAt(0).toString(2).padStart(8, "0")) // Convert each char to binary
      .join(" "); // Join with spaces for readability
  };

  // Handle input change
  const handleInputChange = (e) => {
    setAsciiText(e.target.value);
  };

  // Handle conversion on button click
  const handleConvert = () => {
    const binary = convertToBinary(asciiText);
    setBinaryResult(binary);
  };

  return (
    <div className="container">
      <Helmet>
        <title>ASCI TO BINARY| Hoe tool</title>
        <meta name="description" content=" Convert ASCI TO BINARY CODE" />
        <meta name="keywords" content="asci code to binary code conversion" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h1>ASCII to Binary Code Converter: A Complete Guide</h1>
      <div className={styles.inputsection}>
        <input
          type="text"
          value={asciiText}
          onChange={handleInputChange}
          placeholder="Enter ASCII text"
        />
        <button onClick={handleConvert}>Convert to Binary</button>
      </div>
      {binaryResult && (
        <div className={styles.outputsection}>
          <h3>Binary Output:</h3>
          <p>{binaryResult}</p>
        </div>
      )}
      <Asciicomponent />
    </div>
  );
}

export default App;
