import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Footer from "./component/footer";
import Header from "./component/header";

function App() {
  return (
    <Router>
      <div>
        <Header />

        <Footer />
      </div>
    </Router>
  );
}

export default App;
