import React from "react";
import { Link } from "react-router-dom";
import styles from "./../style/about.module.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className={styles.aboutuscontainer}>
      <Helmet>
        <title>About Us| Hoe tool</title>
        <meta
          name="description"
          content=" Welcome to Hoetool, where we bring innovative solutions to your needs."
        />
        <meta
          name="keywords"
          content="Hoe tool about us, software, tools, small tool"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className={styles.herosection}>
        <h1>About Us</h1>
        <p>
          Welcome to Hoetool, where we bring innovative solutions to your needs.
        </p>
      </section>

      <section className={styles.missionsection}>
        <h2>Our Mission</h2>
        <p>
          At Hoetool.com, our mission is to provide top-notch services with a
          focus on reliability, customer satisfaction, and cutting-edge
          technology.
        </p>
      </section>

      <section className={styles.valuessection}>
        <h2>Our Values</h2>
        <ul>
          <li>
            <strong>Integrity:</strong> We believe in honest and transparent
            communication in all our operations.
          </li>
          <li>
            <strong>Innovation:</strong> Constantly improving and adapting to
            new trends to serve you better.
          </li>
          <li>
            <strong>Customer Focus:</strong> Your success is our priority, and
            we are here to help you every step of the way.
          </li>
        </ul>
      </section>

      <section className={styles.teamsection}>
        <h2>Meet the Team</h2>
        <p>
          Our team consists of industry experts who are passionate about their
          work and dedicated to ensuring your satisfaction.
        </p>
      </section>

      <section className={styles.contactsection}>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or inquiries, feel free to{" "}
          <Link to="/contact">reach out to us</Link>.
        </p>
      </section>
    </div>
  );
};

export default About;
