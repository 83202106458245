import React, { useState } from "react";
import styles from "./../style/percen.module.css";
import Percentagecomponent from "./Percentagecomponent";
import { Helmet } from "react-helmet";

const CompoundInterest = () => {
  const [principal, setPrincipal] = useState("");
  const [rate, setRate] = useState("");
  const [time, setTime] = useState("");
  const [frequency, setFrequency] = useState("");
  const [amount, setAmount] = useState(null);

  const calculateInterest = (e) => {
    e.preventDefault();
    const P = parseFloat(principal);
    const r = parseFloat(rate) / 100;
    const t = parseFloat(time);
    const n = parseInt(frequency);

    // Compound interest formula: A = P(1 + r/n)^(nt)
    const A = P * Math.pow(1 + r / n, n * t);
    setAmount(A.toFixed(2));
  };

  return (
    <div className="container">
      <Helmet>
        <title>Percentage Calculate| Hoe tool</title>
        <meta
          name="description"
          content="Calculate simple interest and principal interest. Interest calculator tool"
        />
        <meta
          name="keywords"
          content="percentage calculator tool, Hoe tool, percentage software, interest calculator tools, small tool"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.container}>
        <h2>Compound Interest Calculator</h2>
        <form onSubmit={calculateInterest}>
          <div>
            <label>Principal Amount ($): </label>
            <input
              type="number"
              value={principal}
              onChange={(e) => setPrincipal(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Annual Interest Rate (%): </label>
            <input
              type="number"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Time (years): </label>
            <input
              type="number"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Compounding Frequency: </label>
            <select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="1">Annually</option>
              <option value="4">Quarterly</option>
              <option value="12">Monthly</option>
              <option value="365">Daily</option>
            </select>
          </div>
          <button type="submit">Calculate</button>
        </form>

        {amount && (
          <div>
            <h3>Total Amount: ${amount}</h3>
          </div>
        )}
      </div>
      <Percentagecomponent />
    </div>
  );
};

export default CompoundInterest;
