import React, { useState } from "react";
import styles from "./../style/password.module.css";
import Passwordcomponent from "./passwordcomponent";
import { Helmet } from "react-helmet";

// Character sets for password generation
const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numberChars = "0123456789";
const specialChars = "!@#$%";
const easyToSayChars = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ23456789";
const easyToReadChars =
  "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789";

const PasswordGenerator = () => {
  const [passwordLength, setPasswordLength] = useState(8);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSpecial, setIncludeSpecial] = useState(true);
  const [easyToSay, setEasyToSay] = useState(false);
  const [easyToRead, setEasyToRead] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");

  // Function to generate password
  const generatePassword = () => {
    let charSet = "";

    if (easyToSay) {
      charSet = easyToSayChars;
    } else if (easyToRead) {
      charSet = easyToReadChars;
    } else {
      if (includeLowercase) charSet += lowercaseChars;
      if (includeUppercase) charSet += uppercaseChars;
      if (includeNumbers) charSet += numberChars;
      if (includeSpecial) charSet += specialChars;
    }

    if (charSet.length === 0) return;

    let password = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charSet.length);
      password += charSet[randomIndex];
    }

    setGeneratedPassword(password);
  };

  return (
    <div className="container">
    <Helmet>
    <title>Password Generate| Hoe tool</title>
    <meta
      name="description"
      content="Create random strong password, Password generator tool"
    />
    <meta name="keywords" content="password generator tool, password software, tools, small tool" />
    <meta name="robots" content="index, follow" />
  </Helmet>
      <h2>Password Generator</h2>
      <div>
        <label>
          Password Length:
          <input
            type="number"
            value={passwordLength}
            onChange={(e) => setPasswordLength(Number(e.target.value))}
            min="1"
            max="128"
          />
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={easyToSay}
            onChange={(e) => setEasyToSay(e.target.checked)}
          />
          Easy to Say
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={easyToRead}
            onChange={(e) => setEasyToRead(e.target.checked)}
          />
          Easy to Read
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={includeUppercase}
            onChange={(e) => setIncludeUppercase(e.target.checked)}
            disabled={easyToSay || easyToRead}
          />
          Include Uppercase
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={includeLowercase}
            onChange={(e) => setIncludeLowercase(e.target.checked)}
            disabled={easyToSay || easyToRead}
          />
          Include Lowercase
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={includeNumbers}
            onChange={(e) => setIncludeNumbers(e.target.checked)}
            disabled={easyToSay || easyToRead}
          />
          Include Numbers
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={includeSpecial}
            onChange={(e) => setIncludeSpecial(e.target.checked)}
            disabled={easyToSay || easyToRead}
          />
          Include Special Characters (!@#$%)
        </label>
      </div>
      <div>
        <button onClick={generatePassword}>Generate Password</button>
      </div>
      <div>
        <h3>Generated Password:</h3>
        <p>{generatedPassword}</p>
      </div>
      <Passwordcomponent />
    </div>
  );
};

export default PasswordGenerator;
