import React from "react";
import styles from "./../style/home.module.css";
import ageCalculator from "./../images/age-calculator.jpg";
import asciiBinary from "./../images/ASCII-BINARY.jpg";
import timeanddate from "./../images/date-and-time-calculator.jpg";
import passwordGenerator from "./../images/password-generator.jpg";
import percentageCal from "./../images/percentage-calculator.jpg";
import quotesGen from "./../images/quotes generator.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// App data array
const apps = [
  {
    name: "Age Calculator",
    category: "Video Players & Editors",
    rating: 4.2,
    link: "age-calculator",
    icon: ageCalculator, // Replace with actual icon URLs
  },
  {
    name: "ASCII TO BINARY",
    category: "Social",
    rating: 4.2,
    link: "ascii-to-binary",
    icon: asciiBinary,
  },
  {
    name: "Date and Time Converter",
    category: "Social",
    rating: 4.1,
    link: "date-and-time-converter",
    icon: timeanddate,
  },
  {
    name: "Password Generator",
    category: "Communication",
    rating: 4.3,
    link: "password-generator",
    icon: passwordGenerator,
  },
  {
    name: "Percentage Calculator",
    category: "Education",
    rating: 4.6,
    link: "percentage-calculator",
    icon: percentageCal,
  },
  {
    name: "Quotes Generator",
    category: "Social",
    rating: 4.0,
    link: "quotes-generator",
    icon: quotesGen,
  },
];

// AppCard component
const AppCard = ({ app }) => {
  return (
    <div className={styles.center}>
      <Helmet>
        <title>Hoe Tool | Tool and Software</title>
        <meta
          name="description"
          content="Age calcularot, password generator, quotes generator, all tools available"
        />
        <meta name="keywords" content="Hoe tool, software, " />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.articlecard}>
        <Link to={app.link}>
          <div className={styles.content}>
            <p className={styles.date}>
              {app.category} - {app.rating}⭐
            </p>
            <p className={styles.title}>{app.name}</p>
          </div>
          <img src={app.icon} alt="article-cover" />
        </Link>
      </div>
    </div>
  );
};

// Main App component
function Home() {
  return (
    <div className={styles.appcontainer}>
      {apps.map((app, index) => (
        <AppCard key={index} app={app} />
      ))}
    </div>
  );
}

export default Home;
