import React, { useState } from "react";
import styles from "./../style/home.module.css";
import Datecomponent from "./Datecomponent";
import { Helmet } from "react-helmet";

const DateTimeConverter = () => {
  const [inputDate, setInputDate] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [timezone, setTimezone] = useState("UTC");

  const handleDateChange = (e) => {
    setInputDate(e.target.value);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
  };

  const convertDate = () => {
    try {
      let date = new Date(inputDate);
      if (timezone === "local") {
        setFormattedDate(date.toLocaleString());
      } else {
        setFormattedDate(date.toUTCString());
      }
    } catch (error) {
      setFormattedDate("Invalid date");
    }
  };

  return (
    <div
      className="container"
      style={{ textAlign: "center", marginTop: "50px" }}
    >
      <Helmet>
        <title>Date and Time conversion | Hoe tool</title>
        <meta
          name="description"
          content="Date and time conversion in one click, local time to UTC time conversion"
        />
        <meta
          name="keywords"
          content="date and time conversion, UTC to Local, UTC tools, small tool"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h1>Date and Time Converter</h1>
      <input
        type="datetime-local"
        value={inputDate}
        onChange={handleDateChange}
        style={{ padding: "10px", marginRight: "10px" }}
      />
      <select onChange={handleTimezoneChange} style={{ padding: "10px" }}>
        <option value="UTC">UTC</option>
        <option value="local">Local Time</option>
      </select>
      <button
        onClick={convertDate}
        style={{ padding: "10px", marginLeft: "10px", cursor: "pointer" }}
      >
        Convert
      </button>

      <div style={{ marginTop: "20px", fontSize: "18px" }}>
        <strong>Formatted Date:</strong> {formattedDate || "N/A"}
      </div>
      <Datecomponent />
    </div>
  );
};

export default DateTimeConverter;
