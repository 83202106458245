import React, { useState } from "react";
import styles from "./../style/contact.module.css";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission (e.g., send to API or display success message)
    setIsSubmitted(true);
  };

  return (
    <div className={styles.contactuscontainer}>
    <Helmet>
    <title>Contact Us| Hoe tool</title>
    <meta
      name="description"
      content=" Welcome to Hoetool, where we bring innovative solutions to your needs."
    />
    <meta name="keywords" content="Hoe tool about us, software, tools, small tool" />
    <meta name="robots" content="index, follow" />
  </Helmet>
      <h1>Contact Us</h1>
      <p>
        Have questions, feedback, or need help with something? We're here to
        assist you! Reach out to us, and we'll get back to you as soon as
        possible. Fill out the form below or use the contact information to get
        in touch.
      </p>

      <section className="contact-details">
        <h2>Our Contact Details</h2>
        <p>
          <strong>Email:</strong> support@hoetool.com
        </p>

        <p>
          <strong>Address:</strong> Vijay Nagar, Indore, India
        </p>
      </section>
      {isSubmitted ? (
        <div className={styles.thankyoumessage}>
          <h2>Thank you for reaching out!</h2>
          <p>We will get back to you as soon as possible.</p>
        </div>
      ) : (
        <form className={styles.contactform} onSubmit={handleSubmit}>
          <div className={styles.formgroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className={styles.formgroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className={styles.formgroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit" className={styles.submitbutton}>
            Send Message
          </button>
        </form>
      )}
    </div>
  );
};

export default Contact;
