import React from "react";
import styles from "./../style/footer.module.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={(styles.footerSection, styles.about)}>
          <h3>About Us</h3>
          <p>
            We provide the best tools and services for all your needs. Stay
            connected with us on social media for updates.
          </p>
        </div>
        <div className={(styles.footerSection, styles.links)}>
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className={(styles.footerSection, styles.social)}>
          <h3>Follow Us</h3>
          <ul className={styles.socialLinks}>
            <li>
              <a href="https://facebook.com">Facebook</a>
            </li>
            <li>
              <a href="https://twitter.com">Twitter</a>
            </li>
            <li>
              <a href="https://instagram.com">Instagram</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p className={styles.textfooter}>
          &copy; 2024 Hoetool.com | Designed by Rajesh Sada
        </p>
      </div>
    </footer>
  );
};

export default Footer;
