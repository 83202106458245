import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "../component/Home";
import About from "../component/About";
import Contact from "../component/Contact";
import AgeCalc from "./AgeCalc";
import Datetime from "./Datetime";
import Password from "./Password";
import PerCalc from "./PerCalc";
import QuotesGen from "./QuotesGen";
import Ascii from "./Ascii";
import Privacy from "./Privacy";
import myLogo from "./../images/hoetool-logo.png";
import styles from "./../style/header.module.css";

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/">
            <img src={myLogo} alt="Logo" className={styles.logo} />
          </Link>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <Link class="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="about">
                  About
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link" to="contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/age-calculator" element={<AgeCalc />} />
        <Route path="/date-and-time-converter" element={<Datetime />} />
        <Route path="/password-generator" element={<Password />} />
        <Route path="/percentage-calculator" element={<PerCalc />} />
        <Route path="/quotes-generator" element={<QuotesGen />} />
        <Route path="/ascii-to-binary" element={<Ascii />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
    </div>
  );
};

export default Header;
